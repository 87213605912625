@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
/* TODO: Can this be removed? */
/* @import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css"; */
@import "~ngx-toastr/toastr";
@import "@angular/cdk/overlay-prebuilt.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../packages/ui/src/styles.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
Please use the following breakpoints for consistency.

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { ... }
*/

@font-face {
  font-family: "Siemens Sans";
  src: url("/assets/font/SiemensSansProVF_W_Wght.woff2") format("woff2");
  font-style: normal;
}

@font-face {
  font-family: "Siemens Sans";
  src: url("/assets/font/SiemensSansProVF_Italics_W_Wght.woff2") format("woff2");
  font-style: italic;
}

* {
  font-family: "Siemens Sans";
  font-synthesis: none !important; /* fix font weight synthesization on mac. */
}

:root {
  --dark-bg-color: #23233c;
  --highlight-color: #00ffb9;
  --bg-gray-color: #f3f3f0;
  --primary-black-color: #000028;
  --btn-hover-color: #d1fff2;
  --btn-txt-hover-color: #005159;
  --btn-active-color: #196269;
  --grid-header-color: #66667e;
  --hover-gray-color: #e8e8e3;
  --border-gray-color: #4c4c68;
  --btn-primary-color: #007993;
  --btn-disable-color: #b3b3be;
  --btn-delete-color: #d72339;
  --text-border-color: #ccccd4;
  --white-color: #ffffff;
  --text-bgv-color: #ebf7f8;
  --main-padding: 22px;
  --textbox-height: 44px;
  --content-font-size: 14px;
  --large-font-size: 16px;
  --small-font-size: 12px;
  --default-message-gray-color: #66667e80;
  --main-header-height: 44px;
  --error-label-color: #b81e31;
}
/* Removed due to tailwind migration */
/* h1 {
  all: unset !important;
  color: var(--primary-black-color) !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 28.8px !important;
  letter-spacing: 0.005em !important;
  word-wrap: break-word;
} */
h2 {
  all: unset !important ;
  color: var(--primary-black-color) !important;
  font-size: 24px !important;
  font-weight: 900 !important;
  line-height: 28px !important;
  word-wrap: break-word;
}
h3 {
  all: unset !important;
  color: var(--primary-black-color) !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  word-wrap: break-word;
}
h4 {
  all: unset !important;
  color: var(--primary-black-color) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  word-wrap: break-word;
}
h5 {
  all: unset !important;
  color: var(--primary-black-color) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 28px !important;
  word-wrap: break-word;
}

strong {
  font-weight: 600;
}

/* FIXME: Removed !important due to tailwind migration */
label {
  all: unset;
  color: var(--primary-black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}
p {
  color: var(--primary-black-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}
small {
  all: unset;
  color: var(--primary-black-color);
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  word-wrap: break-word !important;
}
.spinner-color {
  color: var(--btn-primary-color);
}
.hc .highcharts-container {
  height: 100% !important;
  width: auto !important;
}

.hc svg {
  width: auto;
  height: 100% !important;
}

body {
  background-color: #f3f3f0 !important;
}

.pad-2 {
  padding: 2% !important;
}

::-webkit-scrollbar {
  width: 0.75vh;
  height: 0.75vh;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(175, 173, 173);
  border-radius: 0.75vh;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 0.75vh;
}

::ng-deep .ngx-slider.vertical .ngx-slider-bubble {
  padding: 0px !important;
}
.proj_description {
  color: #66667e !important;
}
.grid-content {
  height: 50%;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
}

.grid-subcontent {
  height: 50%;
  font-size: var(--small-font-size);
  color: #66667e;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.grid-textoverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.language-panel-class {
  /* margin-top: 30px !important; */
  background-color: var(--bg-gray-color) !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  position: absolute;
  left: -60px;
}
.attributes-panel-class {
  /* margin-top: 30px !important; */
  background-color: white;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid var(--btn-txt-hover-color);
}
.setting-panel-class {
  background-color: white;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid var(--btn-txt-hover-color);
  position: absolute;
  left: 10px;
}
.relay-setting-panel-class {
  background-color: white;
  box-shadow: none !important;
  border-radius: 0px !important;
  border: 1px solid var(--btn-txt-hover-color);
  position: absolute;
  left: 10px;
  min-width: 10px !important;
  width: 80px !important;
}
.text-readonly {
  width: 100% !important;
  height: 2.75rem !important;
  border: 1px solid var(--text-border-color) !important;
  border-radius: 0.125rem !important;
  color: var(--border-gray-color) !important;
  padding-left: 0.625rem !important;
  background-color: white !important;
}

.text-readonly:focus-visible {
  outline: none;
}
.btn-dark-success {
  background-color: #00cccc !important;
  border-color: #00cccc !important;
  color: var(--primary-black-color) !important;
  font-size: 18px !important;
  height: 44px;
  padding: 0 8px;
  letter-spacing: 0.5px;
  outline: none;
  border-radius: 0 !important;
  font-weight: 600 !important;
}

.btn-dark-success:hover {
  background-color: #00ffb9 !important;
  color: var(--primary-black-color) !important;
  border-color: #00ffb9 !important;
}

.btn-dark-success:disabled {
  color: var(--primary-black-color) !important;
  border-color: #737389 !important;
  opacity: 1;
  background-color: #737389 !important;
}
.ng-tooltip {
  background-color: var(--primary-black-color) !important;
  color: #ffffff;
  font-size: var(--small-font-size) !important;
  font-family: "Siemens Sans" !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  position: absolute;
  max-width: 150px;
  text-align: center;
  padding: 3px 8px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent var(--primary-black-color) transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent var(--primary-black-color);
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent var(--primary-black-color) transparent transparent;
}
.ng-tooltip-show {
  opacity: 1;
}
